<template>
  <div class="tabs-container clearfix">
        <div class="tabs-pad">

            <table id="roomsTable" class="pack_inner_table" cellspacing="0" cellpadding="0">
                <thead>
                    <tr>
                        <th class="pack_inner_table_one">סוג החדר</th>
                        <th class="pack_inner_table_two">גיל ילד</th>
                        <th class="pack_inner_table_three">מחיר</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in rooms" :key="i">
                        <td class="pack_inner_table_one"><strong>{{getNameRoomType(item.roomTypeCode)}} {{getNameRoomClass(item.roomClassCode)}} {{labelAdult(item.roomTypeCode)}}  {{labelChild(item.roomTypeCode)}}</strong><br />{{getNameRoomBasis(item.boardBasisCode)}}
                            <!-- bed and breakfast-->
                        </td>
                        <td class="pack_inner_table_two">2-12</td>
                        <td class="pack_inner_table_three">
                          <p>${{item.avgPrice}}</p>
                          <p>סה"כ</p>
                          <span><strong>${{getTotalPrice(item)}}</strong></span>
                        </td>
                    </tr>
                    <!-- <tr id="roomTr_0" rel="0">
                        <td class="pack_inner_table_one"><strong>{{room_name}} {{no_of_adults}} מבוגרים + {{no_of_child}} ילדים </strong><br />{{basis}}
                        </td>
                        <td class="pack_inner_table_two">&nbsp;</td>
                        <td class="pack_inner_table_three">$545</td>
                    </tr>
                    <tr id="roomTr_0" rel="0">
                        <td class="pack_inner_table_one"><strong>{{room_name}} {{no_of_adults}} מבוגרים + {{no_of_child}} ילדים </strong><br />{{basis}}
                        </td>
                        <td class="pack_inner_table_two">&nbsp;</td>
                        <td class="pack_inner_table_three">$545</td>
                    </tr> -->
                </tbody>
                <tfoot>
                    <tr>
                        <!--this is for baby remark baby pay 120$ -->
                        <td class="pack_inner_table_one"><strong>תינוק </strong><br />(חייב במבוגר כמלווה)</td>
                        <td class="pack_inner_table_two">0-2</td>
                        <td class="pack_inner_table_three">$120</td>
                    </tr>
                </tfoot>
            </table>
            <!--this is for adult 2 in room remark  -->
            <ul class="pack_inner_price_comments clearfix">
                <li>שני אנשים ראשונים בחדר משלמים כמבוגר</li>
            </ul>

        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {},
  data() {
    return {
      lang: 'he',
      rooms: [],
      room_name: 'room_name',
      no_of_adults: 2,
      no_of_child: 1,
      basis: 'basis',
    };
  },
  watch: {
    appDealPageData() {
      this.fillData();
    },
  },
  computed: {
    ...mapGetters({
      appDealPageData: 'GET_APP_DEAL_DATA',
      roomTypeList: 'GET_ROOM_TYPE',
    }),
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch('FETCH_ROOM_TYPE');
      await this.$store.dispatch('FETCH_APP_DEAL_PAGE_DATA', { dealId: this.$route.query.dealId, lang: this.lang });
    },
    fillData() {
      this.rooms = this.appDealPageData?.roomRates || [];
    },
    getNameRoomClass(pRoomClass) {
      return this.appDealPageData?.appDealExt?.translations?.Room_Class?.[pRoomClass] || pRoomClass;
    },
    getNameRoomType() {
      return 'חדר';
      // return this.appDealPageData?.appDealExt?.translations?.Room_Type?.[pRoomType] || pRoomType;
    },
    getNameRoomBasis(pRoomBasis) {
      return this.appDealPageData?.appDealExt?.translations?.basisName?.[pRoomBasis] || pRoomBasis;
    },
    getCtAdult(pRoomType) {
      const dataRoomType = this.roomTypeList.find((item) => item.code === pRoomType);
      let adult = 0;
      if (dataRoomType.code === 'DB') {
        adult = 2;
      } else if (dataRoomType.code === 'TR3') {
        adult = 3;
      } else if (dataRoomType) {
        adult = dataRoomType.adultsMin;
      }
      return adult;
    },
    labelAdult(pRoomType) {
      const ctAdult = this.getCtAdult(pRoomType);
      return `${ctAdult} מבוגרים`;
    },
    getCtChild(pRoomType) {
      const dataRoomType = this.roomTypeList.find((item) => item.code === pRoomType);
      let child = 0;
      if (dataRoomType.code === 'DB') {
        child = 0;
      } else if (dataRoomType.code === 'TR3') {
        child = 0;
      } else if (dataRoomType) {
        child = dataRoomType.maxPaX - dataRoomType.adultsMin;
      }
      return child;
    },
    labelChild(pRoomType) {
      const ctChild = this.getCtChild(pRoomType);
      return ctChild > 0 ? `+ ${ctChild} ילדים` : '';
    },
    getTotalPrice(pItem) {
      const ctAdult = this.getCtAdult(pItem.roomTypeCode),
        ctChild = this.getCtChild(pItem.roomTypeCode);
      const totalPrice = (ctAdult + ctChild) * pItem.avgPrice;
      return totalPrice;
    },
  },
};
</script>

<style scoped>
  #roomsTable tbody td > p{
    margin: 10px 0px 2px 0px;
  }
</style>
